<template>
  <div class="help">
    <h4>{{ $t('Message.277') }}</h4>
    <div
      class="help-item"
      v-for="(help, i) in listHelp"
      :key="help.title"
      @click="showModalVideo(help.title, help.idVideo)"
    >
      <div class="slot">{{ i + 1 }}. {{ help.title }}</div>
    </div>
    <b-modal
      :id="`modal-help-${idVideo}`"
      centered
      title="BootstrapVue"
      size="xl"
      hide-footer
      hide-header
      modal-class="modal-help"
    >
      <div class="youtube-section">
        <div class="btn-close" @click="$bvModal.hide(`modal-help-${idVideo}`)">
          X
        </div>
        <div id="player" />
      </div>
    </b-modal>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        player: null,
        titleVideo: '',
        idVideo: '',
      };
    },
    computed: {
      listHelp() {
        return [
          {
            title: this.$t('Message.278'),
            idVideo: '0uH2boGxBXo',
          },
        ];
      },
    },
    methods: {
      loadVideo() {
        const self = this;
        function onPlayerReady(event) {
          event.target.playVideo();
        }
        // eslint-disable-next-line no-unused-vars
        let player = null;

        function setupPlayer() {
          window.YT.ready(() => {
            player = new window.YT.Player('player', {
              width: '100%',
              height: '100%',
              videoId: self.idVideo,
              events: {
                onReady: onPlayerReady,
              },
              playerVars: {
                playsinline: 1,
                controls: 0,
                disablekb: 1,
                fs: 0,
                iv_load_policy: 3,
                rel: 0,
                showinfo: 1,
                wmode: 'opaque',
                origin: 'http://localhost:8080',
              },
            });
          });
        }

        (function loadYoutubeIFrameApiScript() {
          const tag = document.createElement('script');
          tag.src = 'https://www.youtube.com/iframe_api';

          const firstScriptTag = document.getElementsByTagName('script')[0];
          firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

          tag.onload = setupPlayer;
        })();
      },
      async showModalVideo(titleVideo, idVideo) {
        const self = this;
        self.titleVideo = titleVideo;
        self.idVideo = idVideo;
        await self.loadVideo();
        self.$bvModal.show(`modal-help-${self.idVideo}`);
      },
    },
  };
</script>

<style lang="scss">
  .help {
    width: 100%;
    max-width: 1368px;
    position: relative;
    padding: 1.25rem;
    margin: 0 auto;
    @media (max-width: 480px) {
      padding: 5px;
    }
    h4 {
      text-align: center;
      color: #fff;
    }
    .help-item {
      background-image: linear-gradient(
        to right,
        rgba(105, 179, 1, 0.4),
        rgba(85, 89, 102, 0.4)
      );
      display: flex;
      width: 100%;
      max-width: 500px;
      margin: 15px auto 0px;
      padding: 0px 2rem;
      height: 2.5rem;
      line-height: 2.5rem;
      border-radius: 1.25rem;
      @media (max-width: 480px) {
        padding: 5px 1rem;
        height: auto;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        line-height: 20px;
      }
      &:hover {
        border: 1px solid #7bc514;
      }
      .slot {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        color: rgb(255, 255, 255);
        cursor: pointer;
      }
    }
  }
  .modal-help {
    .modal-dialog {
      @media (max-width: 575px) {
        margin: 0px;
        padding: 0.5em;
      }
      .modal-body {
        height: 500px;
        padding: 0px;
        margin-top: 0px;
        .youtube-section {
          height: 100%;
          position: relative;
          z-index: 1;
          .btn-close {
            position: absolute;
            top: 0px;
            right: 0px;
            color: red;
            background-color: #ffffff;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-content: center;
            line-height: 30px;
            font-weight: bold;
            cursor: pointer;
          }
        }
      }
    }
  }
</style>
